.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 700px;
    height: 400px;
    border-radius: 10px;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 100px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 100px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow:hover{
    font-size: 45px;
}

.right-arrow:hover{
    font-size: 45px;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.slider-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.dot {
    margin: 0 5px;
    cursor: pointer;
}
.dot:hover{
    font-size: 15px;
}
.dot.active {
    color: red;
}

@media (max-width:1115px)  { 
    .image {
        width: 600px;
        height: 400px;
        border-radius: 10px;
    }
}

@media (max-width:1030px)  { 
    .image {
        width: 500px;
        height: 400px;
        border-radius: 10px;
    }
}

@media (max-width:900px)  { 
    .image {
        width: 400px;
        height: 300px;
        border-radius: 10px;
    }
}

@media (max-width:800px)  { 
    .image {
        width: 400px;
        height: 300px;
        border-radius: 10px;
    }
    .slider-dots {
        position: absolute;
        bottom: 40px;
        font-size: 11px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
    }

    .dot:hover{
        font-size: 10px;
    }
}


@media (max-width:750px)  { 

    .image {
        width: 400px;
        height: 300px;
        border-radius: 10px;
    }
    .slider-dots {
        position: absolute;
        bottom: 10px;
        font-size: 11px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap; 
        justify-content: center;
    }

    .slider-dot {
        margin-top: 10px;
    }
    .dot:hover{
        font-size: 10px;
    }
    .left-arrow {
        position: absolute;
        top: 80%;
        right: 100px;
        font-size: 3rem;
        color: #000;
        z-index: 10;
        cursor: pointer;
        user-select: none;
    }
    .right-arrow {
        position: absolute;
        top: 80%;
        right: 100px;
        font-size: 3rem;
        color: #000;
        z-index: 10;
        cursor: pointer;
        user-select: none;
    }
    .dot {
        margin: 5px 5px;
        cursor: pointer;
    }
    .dot:hover{
        font-size: 11px;
    }
}


@media (max-width:450px)  { 
    .slider {
        position: relative;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image {
        width: 300px;
        height: 200px;
        border-radius: 10px;
    }
    .slider-dots {
        position: absolute;
        bottom: -80px;
        font-size: 11px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap; 
        justify-content: center;
    }
}