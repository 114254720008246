.categories-container {
    display: flex;
    margin-top: 80px;
    flex-direction: column;
    gap: 10px;
    overflow-x: auto;
}

.category-container {
    flex: 0 0 auto; 
    width: 98%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
}

.category-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-family: system-ui;
}

.product-container {
    display: flex;
    flex-wrap: nowrap; 
    gap: 10px; 
    overflow-x: auto; 
}


.category-title-fixed {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #8a8a8a;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #6b6b6b;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

