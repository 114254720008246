.container {
    position: relative;
    text-align: center;
}

.Mainİmage{
    width: 100%;
    height: 700px;
    align-items: center;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: -1;
    filter: blur(4px);
    right: 7px;
}

.imageText{
    top: 50%;
    left: 50%;
    transform: translate(-100%, -100%);
    font-size: 50px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: absolute;
    z-index: 1;
    color: black;

}


.imageText {
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.4); 
    color: white;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
  }

@media (max-width:500px)  { 
    .Mainİmage{
        width: 100%;
        height: 300px;
        align-items: center;
        position: relative;
        justify-content: center;
        align-items: center;
        z-index: -1;
        filter: blur(4px);
        right: 7px;
    }
    
    .imageText{
        top: 50%;
        left: 50%;
        transform: translate(-100%, -100%);
        font-size: 25px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        position: absolute;
        z-index: 1;
        color: black;
    
    }
    
    
    .imageText {
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0, 0.4); 
        color: white;
        font-weight: bold;
        border: 3px solid #f1f1f1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 20px;
        text-align: center;
    }
}